.footer-main {
    width: 100%;
    height: 100%;
    background-color: red;
    margin-top: 80px;
}

.footer-con {
    max-width: 1140px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 60px;
    padding-bottom: 60px;
}


.footer-first-part {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.footer-info {
    display: flex;
    gap: 10px;
    align-items: center;
}

.footer-icon {
    color: #fff;
    font-size: 20px;
}

.footer-txt {
    color: #fff;
    font-size: 16px;
    text-decoration: none;
}

.footer-txt-margin {
    margin: 0px;
}

.footer-links {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    gap: 20px;
    list-style: none;
    padding: 0px;
    margin: 0px;
}

.footer-link {
    font-size: 16px;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
}

.footer-link-heading {
    font-size: 18px;
    color: #fff;
    font-weight: 800;
    margin: 0px;
}

.footer-second-part {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 82px;
}

.footer-logobox {
    background-color: #fff;
    max-width: 200px;
    max-height: 200px;
    padding-left: 5px;
    padding-right: 5px;
}

.footer-logo {
    width: 100%;
    height: 100%;
}

@media screen and (max-width:1140px) {
    .footer-con {
        padding-left: 15px;
        padding-right: 15px;
    }

    .nav-links {
        margin: 0px 15px 0px 0px;
    }
}

@media screen and (max-width:975px) {
    .footer-con {
        flex-wrap: wrap;
        justify-content: space-evenly;
        gap: 50px;
    }
}

@media screen and (max-width: 500px) {
    .footer-map {
        width: 350px;
        height: 350px;
    }

    .footer-con {
        justify-content: start;
    }

    .footer-second-part {
        margin: 0px;
    }
}