.ourproduct-sec {
    width: 100%;
    height: 100%;
    margin-top: 80px;

}

.ourproduct-con {
    max-width: 1140px;
    margin: 0 auto;
}

.ourproduct-heading {
    font-size: 28px;
    font-weight: 600;
    text-align: center;
    margin: 0px;
}

/*  */

.trustee-page-card-container {
    /* padding-top: 25px; */
}

.trustee-page-card-table {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 60px;

}


.trustee-pg-card {
    position: relative;
    width: 250px;
    height: 300px;
    margin: 20px;
}

.trustee-pg-card .facee {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    /* border-radius: 10px; */
    overflow: hidden;
    transition: .5s;
}

.trustee-pg-card .frontt {
    transform: perspective(600px) rotateY(0deg);
    /* box-shadow: 0 5px 10px #000; */
}

.trustee-pg-card .frontt img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: unset;
}

.trustee-pg-card .frontt h4 {
    position: absolute;
    bottom: -30px;
    width: 100%;
    height: 45px;
    line-height: 32px;
    color: white;
    background: rgba(0, 0, 0, .4);
    text-align: center;
}

.trustee-pg-card .backk {
    transform: perspective(600px) rotateY(180deg);
    /* background: var(--themecolor, #c6c09c); */
    background: var(--themecolor, #F9EBEA);
    padding: 15px;
    color: #f3f3f3;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    text-align: center;
    box-shadow: 0 5px 10px #000;
}

.trustee-pg-card .backk h3 {
    font-size: 30px;
    /* margin-top: 20px; */
    letter-spacing: 2px;
}

.trustee-pg-card .backk p {
    letter-spacing: 1px;
}

.trustee-pg-card:hover .frontt {
    transform: perspective(600px) rotateY(180deg);
}

.trustee-pg-card:hover .backk {
    transform: perspective(600px) rotateY(360deg);
}

.box {
    /* text-align: center; */
    color: #000;
}

.ourpoduct-card-head {
    color: #000;
    font-weight: 600;
}

.card-btn-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media(max-width:991px) {
    .trustee-page-card-container {
        /* margin-top: -50px; */
    }
}