.companyinfo-sec {
    height: 100%;
    width: 100%;
    background-color: #F9EBEA;
    margin-top: 80px;
}

.companyinfo-con {
    max-width: 1140px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding-top: 60px;
    padding-bottom: 60px;
}

.companyinfo-part {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 500px;
}

.companyinfo-heading {
    color: #333;
    font-weight: 600;
    font-size: 26px;
    margin: 0px;
}

.companyinfo-desc {
    color: #333;
    font-size: 16px;
    margin: 0px;
    text-align: justify;
}


/* Media Query */

@media screen and (max-width: 1140px) {
    .companyinfo-con {
        padding-left: 15px;
        padding-right: 15px;
    }

    .companyinfo-part {
        max-width: 400px;
    }
}

@media screen and (max-width: 875px) {
    .companyinfo-con {
        flex-direction: column;
        align-items: center;
        gap: 30px;
    }

}