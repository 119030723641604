.product-sec {
    width: 100%;
    height: 100%;
}

.product-con {
    max-width: 1140px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 40px;
    justify-content: center;

}

.product-head {
    font-size: 28px;
    color: #333;
    text-align: center;
    font-weight: 600;
    margin-bottom: 60px;
}

.product-box {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    max-width: 275px;
    min-width: 275px;
    max-height: 425px;
    min-height: 425px;
}

.product-imgbox {
    width: 250px;
    height: 250px;
}

.product-img {
    width: 100%;
    height: 100%;
}

.product-name {
    font-size: 22px;
    color: #333;
    font-weight: 600;
    margin: 0px;
}
.product-sub-name {
    font-size: 16px;
    color: #333;
    font-weight: 400;
    margin: 0px;
}

.product-desc {
    font-size: 16px;
    color: #333;
    text-align: justify;
    margin: 0px;
}


@media screen and (max-width: 1140px) {
    .product-desc {
        padding-left: 15px;
        padding-right: 15px;
    }
}