* {
    box-sizing: border-box;
}

.nav-main {
    max-width: 1140px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    margin-top: 20px;
    margin-bottom: 60px;
}

.navbar-logobox {
    max-width: 175px;
    max-height: 175px;
}

.navbar-logo {
    width: 100%;
    height: 100%;
}

.nav-links {
    display: flex;
    gap: 20px;
    align-items: center;
    list-style: none;
}

.nav-link {
    font-size: 16px;
    color: red;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
}


.nav-btn-icon-part {
    background-color: #fff;
    height: 100px;
    width: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
}

.nav-btn {
    font-size: 30px;
    color: red;
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    cursor: pointer;
    padding-top: 30px;
}




/* Mobile View */

.mobile-nav-main {
    max-width: 550px;
    background-color: #fff;
}

.mobile-nav-links {
    display: flex;
    flex-direction: column;
    gap: 20px;
    list-style: none;
}

.mobile-nav-link {
    color: red;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
}

@media screen and (max-width: 1140px) {
    .nav-main {
        padding-left: 15px;
    }
}

@media screen and (max-width: 550px) {
    .nav-btn-icon-part {
        display: block;
    }

    .nav-links-part {
        display: none;
    }

    .nav-main {
        margin-top: 0px;
        margin-bottom: 0px;
    }
}

@media screen and (max-width:420px) {
    .navbar-logobox {
        max-width: 125px;
        max-height: 125px;
    }

    .mobile-nav-links {
        margin-top: 0px;
    }
}